<template>
  <div>
    <div class="card" style="height: 40px">
        <div style="flex: 0; display: flex; float: left">
          <div style="width: 70px; height: 40px; margin-right: 25px">
            <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              :loading="exportLoading"
              @click="handleExport()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="float: right; display: flex">
          <SearchConf function_code="ewechat_customer" />
          <TableConf function_code="ewechat_customer" @onRowList="handleRowList" />
          <!-- <ExportConf function_code="clientuser" /> -->
        </div>
      </div>
      <div
        style="
          padding: 10px 0;
          border: 1px solid #e8e8e8;
          border-top: none;
          background: white;
          border-radius: 0 0 5px 5px;
        "
      >
        <Search @onCond="handleCond" function_code="ewechat_customer" />
      </div>
      <div style="margin-top: 15px; display: flex; width: 100%">
        <div style="width: 100%" v-if="rowList.length !== 0">
          <div class="table" style="border-top: 1px solid #e8e8e8">
            <el-table
              ref="multipleTable"
              :data="tableData"
              stripe
              border
              tooltip-effect="dark"
              height="620"
              @selection-change="handleSelectionChange"
              v-loading="tableLoading"
              @row-dblclick="handleDalclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <template v-for="(item, index) in rowList">
                <el-table-column
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                  style="padding:0;"
                  class-name="goodsInfo"
                >
                  <template slot-scope="scope" style="padding:0;"  cell-class-name="1111">
                    <div  style="padding:0;">
                      <!-- is_merge//是否合并 1是 0否 -->
                      <div v-if="item.is_merge == 0">
                        <div v-for="(items,index) in scope.row.follow_list" :key="index" >
                          <div   :style="index!=scope.row.follow_list.length-1?'line-height: 40px;margin: 0 20px;border-bottom: 1px solid #ddd;':'line-height: 40px; margin: 0 20px;'" v-if="items[item.field_alias]">{{items[item.field_alias]}}</div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="item.field_alias === 'external_name'||item.field_alias== 'tags_name'">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="scope.row[item.field_alias]"
                            placement="top"
                          >
                            <div
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                              "
                            >
                              {{ scope.row[item.field_alias] }}
                            </div>
                          </el-tooltip>
                        </div>
                        <div v-else>{{ scope.row[item.field_alias]}}</div>

                      </div>
                    </div>
                  </template></el-table-column
                >
              </template>
              <!-- <el-table-column
                align="center"
                fixed="right"
                prop="operation"
                label="操作"
                width="180"
              >
                <template slot-scope="scope">
                  <div class="options">
                    <el-select
                      v-model="scope.row.select"
                      placeholder="请选择"
                      @change="handleDetails($event, scope.row)"
                    >
                      <el-option label="详情" :value="1"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination
              background
              :current-page="page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              :page-sizes="[15, 30, 40,50]"
              :page-size="15"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    <memberDetail
      :showCoupon="showCoupon"
      v-if="isDetails"
      :client_user_id="client_user_id"
      :client_system_id="client_system_id"
      :client_user_master_id="client_user_master_id"
      @hide="getList"
      memberType="wholeMember"
    />
  </div>
</template>

<script>
import SearchConf from "@/components/searchConf/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";
import Search from "@/components/search/Index.vue";
import Enterprise  from "@/components/search/enterpriseSearch.vue";
import memberDetail from "@/views/member/memberManage/memberDetail/Index.vue";
import Send from "../../components/Send";
import {
  exportCustomer,
  getCustomerList
} from "@/api/member/memberList/index.js";
export default {
  data() {
    return {
      showCoupon: true, // 详情页是否展示优惠券tab,陈鸿明留
      isDetails: false,
      exportLoading: false,
      tableData: [],
      nterprisetableData:[],
      rowList: [],
      total: 0,
      page: 1,
      limit: 12,
      hierarchy_id: null,
      client_system_id: 0,
      client_system_title: null,
      storeList: [],
      tableLoading: false,
      client_user_id: 0,
      client_user_ids: [],
      client_user_master_ids:[],
      client_user_master_id:0,

      cond: {},
      setIntForm: {},
      setSavingForm: {},
      formLabelWidth: "100px",
      loading: false,
      tableLoading: false,
    };
  },
  created() {
    this.getCustomerList();
  },
  components: {
    Send,
    SearchConf,
    TableConf,
    ExportConf,
    Search,
    Enterprise,
    memberDetail,
  },
  methods: {
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = [];
      this.rowList = rowList.filter((item) => item.field_alias !== "avatar");
    },

    /*
     *  导出
     */
    handleExport() {
      if (JSON.stringify(this.cond) === "{}") {
        this.cond = {
          page: 1,
          limit: this.total,
        };
      }
      const data = {
        // hierarchy_id: this.hierarchy_id,
        // client_system_id: this.client_system_id,
        ...this.cond,
        // function_code: "clientuser",
      };
      this.exportLoading = true;
      exportCustomer(data)
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `企微客户列表导出.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
          this.exportLoading = false;
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.exportLoading = false;
        });
    },
    //企微客户列表
    getCustomerList(){
      this.tableLoading = true;
      this.isDetails = false;
      const data = {
        ...this.cond,
        page: this.page,
        limit: this.limit,
      };
      getCustomerList(data).then((res) => {
        if (res.code === 1) {
          this.tableLoading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleDalclick(row) {
      this.client_user_id = row.client_user_id;
      this.isDetails = true;
    },

    handleDetails(event, row) {
      if (event === 1) {
        this.isDetails = true;
        this.client_user_id = row.client_user_id;
        this.client_user_master_id=row.client_user_master_id
      }
    },
    // 选择发放优惠券*****
    handleSelectionChange(val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item.client_user_master_id);
      });
      this.client_user_master_ids = [...new Set(arr)];
      console.log(this.client_user_master_ids,"选择中哟挥拳");
    },
    handleCond(cond) {
      this.cond = { ...cond };
      this.page = 1;
      this.getCustomerList();
    },
    getImportFile() {},
    handleImportSuccess() {},
    handleSizeChange(val) {
      this.limit = val;
      this.getCustomerList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCustomerList();
    },
  },
};
</script>
<style lang="less">
  .goodsInfo{
      .cell {
        padding: 0 !important;
        overflow: unset;

      }
    }
</style>
<style lang="less" scoped>

.storeList {
  padding-top: 22px;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;

  .storeTitle {
    margin-bottom: 5px;
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    font-size: 16px;

    .span {
      margin: auto 10px auto 0;
      width: 4px;
      height: 16px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .childStore {
    margin: 5px 0 5px 35px;
    line-height: 35px;
  }

  .store {
    padding-left: 17px;
    width: 100%;
    background: white;
    padding: 10px 0;
    font-size: 14px;
    .text {
      margin-left: 15px;
    }
  }
  .store:hover {
    cursor: pointer;
  }

  .selectStore {
    background-color: #ecf5ff;
  }

}
</style>
