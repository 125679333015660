<template>
  <div :style="{ height: height }" class="wrapper">
    <div class="middle" id="cond-middle">
      <el-form :model="cond" :inline="true" class="form">
        <template v-for="item in usedSearchList">
          <template v-if="item.is_search">
            <el-form-item
              :label="item.field_text"
              :key="item.field"
              label-width="130px"
            >
              <el-input
                :placeholder="'请输入' + item.field_text"
                v-model="cond[item.field]"
                style="width: 200px; margin-right: 10px"
                clearable
                v-if="item.field_type === 'string'"
                @keydown.enter.native="handleEnter($event)"
              ></el-input>
              <el-select
                clearable
                filterable
                v-model="cond[item.field]"
                v-if="item.field_type === 'fixed'"
                style="width: 200px; margin-right: 10px"
              >
                <template v-for="(option, optionIndex) in item.options">
                  <template>
                    <el-option
                      :label="option.text"
                      :value="option.value"
                      :key="optionIndex"
                    >
                    </el-option>
                  </template>
                </template>
              </el-select>
              <el-select
                clearable
                filterable
                :placeholder="'请选择' + item.field_text"
                v-model="cond[item.field]"
                v-if="
                  item.field === 'warehouse_id_allgoods' &&
                  function_code === 'allGoodsList'
                "
              >
                <template>
                  <el-option-group
                    v-for="(option, optionIndex) in item.options"
                    :key="optionIndex"
                    :label="option.shop"
                  >
                    <el-option
                      v-for="item in option.list"
                      :key="item.warehouse_id"
                      :label="item.warehouse_name"
                      :value="item.warehouse_id"
                    >
                    </el-option>
                  </el-option-group>
                </template>
              </el-select>
              <el-select
                clearable
                filterable
                :placeholder="'请选择' + item.field_text"
                v-model="cond[item.field]"
                :multiple="item.field_type === 'array'"
                :collapse-tags="item.field_type === 'array'"
                v-if="
                  (item.field_type === 'array' &&
                    item.field !== 'warehouse_id_allgoods') ||
                  item.field_type === 'radio'
                "
                style="width: 200px; margin-right: 10px"
              >
                <template v-for="option in item.options">
                  <template>
                    <el-option
                      :label="
                        item.field_text === '品类'
                          ? option[item.keys[2]]
                          : option[item.keys[1]]
                      "
                      :value="option[item.keys[0]]"
                      :key="option[item.keys[0]]"
                    >
                    </el-option>
                  </template>
                  <!-- <template v-else>
                    <el-option
                    v-if="option[item.keys[0]] !== userInfo.hierarchy_id"
                    :label="
                      item.field_text === '品类'
                        ? option[item.keys[2]]
                        : option[item.keys[1]]
                    "
                    :value="option[item.keys[0]]"
                    :key="option[item.keys[0]]"
                  >
                  </el-option>
                  </template> -->
                </template>
              </el-select>
              <el-date-picker
                v-if="item.field_type === 'date'"
                v-model="cond[item.field]"
                type="daterange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <template v-if="item.field_type === 'number_range'">
                <el-input
                  placeholder="最小值"
                  v-model="cond[item.field1]"
                  style="width: 88px; margin-right: 5px"
                  clearable
                ></el-input>
                <span style="margin-right: 5px">至</span>
                <el-input
                  placeholder="最大值"
                  v-model="cond[item.field2]"
                  style="width: 88px; margin-right: 10px"
                  clearable
                ></el-input>
              </template>
            </el-form-item>
          </template>
        </template>
      </el-form>
      <div style="margin: 0 10px 0 auto">
        <el-button
          @click="handleHeight"
          style="
            width: 40px;
            height: 40px;
            line-height: 36px;
            text-align: center;
            padding: 0;
          "
        >
          <i class="el-icon-caret-bottom" v-if="height === '40px'"></i>
          <i class="el-icon-caret-top" v-else></i>
        </el-button>
      </div>
      <div style="display: flex; margin-right: 15px">
        <el-button type="primary" @click="fetchData" style="height: 40px"
          >搜索</el-button
        >
        <el-button @click="resetForm" style="height: 40px">重置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "good-storage";
import Sortable from "sortablejs/modular/sortable.core.esm.js";
import bus from "@/utils/bus.js";
import {
  commonSearchReq,
  getOptionReq,
  setSearchReq,
} from "@/components/cond/index";

export default {
  name: "Cond",
  data() {
    return {
      rowList: [],
      tableColumn: [
        { title: "搜索字段", field: "field_text", minWidth: "250" },
      ],
      userInfo: {},
      offsetHeight: "",
      height: "40px",
      cond: {},
      usedSearchList: [], // 已使用的条件集合
      noUsedSearchList: [], // 未使用的条件集合
      usedCheckList: [], // 使用条件的勾选情况
      noUsedCheckList: [], // 未使用条件的勾选情况
    };
  },
  props: {
    function_code: String,
    mode: Number,
    other_code: String,
    tab: {
      type: Number,
      default() {
        return 0;
      },
    },
    show: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    cond: {
      handler() {
        this.$emit("setCond", this.cond);
      },
      deep: true,
    },
    mode: {
      handler() {
        this.commonSearch();
      },
    },
  },
  mounted() {
    bus.$on("commonSearch", this.commonSearch);
    console.log(this.commonSearch)
  },
  created() {
    this.commonSearch();
    this.userInfo = storage.get("userInfo");
  },
  methods: {
    resetForm() {
      this.cond = {};
      this.$emit("onReset");
      this.$emit("onCond", this.cond);
    },
    handleHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.height = this.height === "40px" ? this.offsetHeight : "40px";
    },
    handleEnter(e) {
      this.fetchData();
      // if (e.target.value) {
      // }
    },
    fetchData() {
      const cond = { ...this.cond };
      let errorKey = [];
      // 需要判断有没有范围类型 “speArray1, speArray2"
      for (let key in cond) {
        if (key.indexOf("speArray") > -1) {
          console.log(cond[key]);
          let newKey = key.substr(0, key.length - 9);
          if (!cond[newKey]) {
            cond[newKey] = [];
            cond[newKey][0] = cond[key];
          } else {
            cond[newKey][1] = cond[key];
          }
          delete cond[key];
          if (Number(cond[newKey][0]) > Number(cond[newKey][1])) {
            // this.$message.warning('范围搜索前面的值必须不小于后面的值');
            errorKey.push(newKey);
          }
          if (cond[newKey][0] === "" && cond[newKey][1] === "") {
            delete cond[newKey];
          }
        }
        if (cond[key] === "") {
          delete cond[key];
        }
      }
      if (errorKey.length) {
        for (let i = 0; i < this.usedSearchList.length; i += 1) {
          const item = this.usedSearchList[i];
          if (errorKey[0] === item.field) {
            this.$message.warning(
              `${item.field_text}范围 “前面的值” 不得大于 “后面的值”`
            );
            return;
          }
        }
      }

      this.$emit("onCond", cond);
    },
    commonSearch() {
      commonSearchReq(this.function_code, this.tab, this.mode).then((res) => {
        if (res.code === 1) {
           console.log(7)
          console.log(res)
          this.rowList = JSON.parse(JSON.stringify(res.data));
          this.height = "40px";
          // 需要区分使用和不使用的条件
          this.usedSearchList = res.data.filter((item) => item.s_is_use);
          this.noUsedSearchList = res.data.filter((item) => !item.s_is_use);
          // 搜索下拉框的选项
          this.usedSearchList.forEach((item, index) => {
            // 范围类型的需要做处理
            if (item.field_type === "number_range") {
              item.field1 = `${item.field}speArray1`;
              item.field2 = `${item.field}speArray2`;
            }
            if (item.select_code) {
              let obj = {};
              const data = {};
              // 货品清单列表 》 货品标签下拉接口参数
              // if (item.select_code === "getGoodsTagList") {
              //   data.isAll = 0;
              // }
              // 拼参数
              if (item.param) {
                if (item.param.length) {
                  item.param.forEach((param) => {
                    data[param.key] = param.value;
                  });
                }
              }
              getOptionReq(item.select_code, data).then((result) => {
                if (result.code === 1) {
                  obj = { ...item };
                  if (obj.field_text === "品类") {
                    obj.options = this.handleArr(result.data);
                  } else {
                    obj.options = result.data;
                  }

                  // 需要获取key值
                  if (result.data.length) {
                    const option = result.data[0];
                    const keys = [];
                    for (const key in option) {
                      keys.push(key);
                    }
                    obj.keys = keys;
                    this.usedSearchList.splice(index, 1, obj);
                  }
                }
              });
            }
          });
          this.$nextTick(() => {
            try {
              const dom = document.getElementById("cond-middle");
              this.offsetHeight = dom.offsetHeight + "px";
            } catch (error) {}
          });
        }
      });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  .middle {
    display: flex;
    overflow: hidden;
  }
}
</style>
